.appBar {
  background-color: rgb(7, 75, 24) !important;
}

.appBarLogo {
  height: 40px;
}

.appBarContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  height: 100%;
}
